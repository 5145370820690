const NOTIFICATION_DURATION = 4000

interface NotificationService {
  success: Function
  warn: Function
  error: Function
  dark: Function
  info: Function
}

const NotificationTemplate = `<div class="content px-4 py-3 pr-10 cursor-default flex items-center">
    <span class="title mb-1 text-lg font-medium">Title</span>
    <a href="#" class="close absolute top-0 bottom-0 right-2 text-2xl text-center flex items-center justify-center h-4 w-4 m-auto font-medium">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </a>
    <p>Message</p>
  </div>
`

interface NotificationOptions {
  title?: string
  message?: string
  className?: string
  effect?: string
}

class NotifyX {
  public el: HTMLElement = document.createElement('LI')

  public _effect = ''

  public timer: any = null

  constructor(options: NotificationOptions) {
    // this.el = document.createElement('LI')
    this.el.className =
      'notification closable duration-500 transition-all opacity-0 -translate-y-full mb-2 relative max-w-[600px] min-w-[250px] z-1 rounded shadow mx-auto'
    this.el.innerHTML = NotificationTemplate
    this.el.setAttribute('data-cy', 'notification')
    this.effect('scale')
    this.render(options)

    if (options.className) {
      this.el.className += ` ${options.className}`
    }
    if (options.effect) {
      this.effect(options.effect)
    }
  }

  render(options: NotificationOptions) {
    const el = this.el,
      title = options.title,
      msg = options.message

    // @ts-ignore
    el.querySelector('.close').addEventListener('click', () => {
      // self.emit('close');
      this.hide()
      return false
    })

    el.addEventListener('click', (e) => {
      e.preventDefault()
      this.hide()
      // self.emit('click', e);
    })

    // @ts-ignore
    el.querySelector('.title').innerText = title
    if (!title) {
      // @ts-ignore
      el.querySelector('.title').remove()
    }

    // message
    if ('string' == typeof msg) {
      // @ts-ignore
      el.querySelector('p').innerText = msg
    }

    setTimeout(function () {
      el.className = el.className.replace(
        ' opacity-0 -translate-y-full',
        'opacity-1 translate-y-0'
      )
    }, 50)

    return el
  }

  closable() {
    this.el.className += ` closable`
    return this
  }

  effect(type: string) {
    this._effect = type
    this.el.className = this.el.className + ' ' + type
    return this
  }

  show() {
    let list = document.getElementById('notifications')
    if (!list) {
      list = document.createElement('UL')
      list.id = 'notifications'
      list.className =
        'fixed bottom-4 left-8 right-8 mx-auto z-50 flex flex-col items-start'
      document.body.appendChild(list)
    }

    list.insertBefore(this.el, list.firstChild)
    return this
  }

  type(type: string) {
    const typesClasses: any = {
      error: 'bg-red-500 text-white',
      warn: 'bg-yellow-500 text-white',
      success: 'bg-green-500 text-white',
      dark: 'bg-grey-500 text-white',
      info: 'bg-blue-500 text-white',
    }

    this.el.className = `${this.el.className} ${type} ${
      typesClasses[type] ?? ''
    }`

    return this
  }

  sticky() {
    return this.hide(0).closable()
  }

  hide(ms: any = null) {
    // duration
    if ('number' === typeof ms) {
      clearTimeout(this.timer)

      if (!ms) {
        return this
      }

      this.timer = setTimeout(() => this.hide(), ms)

      return this
    }

    // hide / remove
    this.el.className = this.el.className + ' opacity-0 translate-y-full'
    if (this._effect) {
      setTimeout(() => this.remove(), 500, this)
    } else {
      this.remove()
    }

    return this
  }

  remove() {
    this.el.remove()
    return this
  }
}

function notify(
  message: string,
  title = '',
  type = 'info',
  timer = NOTIFICATION_DURATION
) {
  const data: NotificationOptions = { title, message }
  const notification = new NotifyX(data)

  notification.show().hide(timer)
  if (type) {
    notification.type(type)
  }
}

export const Notify: NotificationService = {
  success: (message: string, title: string) =>
    notify(message, title, 'success'),
  warn: (message: string, title: string) => notify(message, title, 'warn'),
  error: (message: string, title: string) => notify(message, title, 'error'),
  dark: (message: string, title: string) => notify(message, title, 'dark'),
  info: (message: string, title: string) => notify(message, title, 'info'),
}
