import { MouseEventHandler, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { classNames } from '@/lib/misc/Utils'

export type ButtonStyle =
  | 'primary'
  | 'secondary'
  | 'normal'
  | 'success'
  | 'danger'
  | 'light'
  | 'flat'

const buttonStylesMap: Record<ButtonStyle, string> = {
  primary:
    'bg-accent-60 border-accent-60 hover:bg-white text-white hover:text-accent-60',
  secondary: 'border-accent-60 text-accent-60 hover:opacity-50 duration-200',
  normal:
    'bg-white border-accent-60 text-accent-60 hover:bg-accent-60 hover:text-white',
  success:
    'bg-status-good border-status-good hover:bg-status-good/70 hover:border-status-good/70 text-white',
  danger:
    'bg-red-500 border-red-500 hover:bg-red-400 hover:border-red-400 text-white',
  light: 'bg-accent-20 hover:bg-accent-30 border-0 text-brand-4',
  flat: 'bg-white hover:bg-accent-30 border-0 text-brand-4',
}

interface ButtonProps {
  className?: string
  icon?: ReactNode
  iconSuffix?: ReactNode
  label?: string
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  href?: string
  type?: 'button' | 'submit'
  children?: ReactNode
  disabled?: boolean
  style?: ButtonStyle
  fullWidth?: boolean
  id?: string
  cy?: string
  as?: 'button' | 'link' | 'anchor'
}

const Button = ({
  children,
  icon,
  iconSuffix,
  disabled,
  className,
  onClick,
  style,
  fullWidth,
  type = 'button',
  href,
  id,
  cy,
  as = 'button',
}: ButtonProps) => {
  const buttonStyles = classNames(
    'flex items-center gap-2.5 py-2 border text-sm font-medium rounded-md select-none transition-all duration-300',
    style ? buttonStylesMap[style] : 'border-transparent',
    disabled ? 'opacity-50 cursor-not-allowed' : '',
    className || '',
    !!children ? 'px-3.5' : 'px-2',
    !!icon && 'pl-2',
    !!iconSuffix && 'pr-2',
    fullWidth && 'w-full justify-center'
  )
  const renderContent = () => (
    <>
      {!!icon && <div className="flex items-center">{icon}</div>}
      {!!children && <div className="leading-tighter">{children}</div>}
      {!!iconSuffix && <div className="flex items-center">{iconSuffix}</div>}
    </>
  )

  if (as === 'link' && href) {
    return (
      <Link to={href} className={buttonStyles} id={id} data-cy={cy}>
        {renderContent()}
      </Link>
    )
  }

  if (as === 'anchor' && href) {
    return (
      <a href={href} className={buttonStyles} id={id} data-cy={cy}>
        {renderContent()}
      </a>
    )
  }

  return (
    <button
      onClick={onClick}
      type={type}
      id={id}
      data-cy={cy}
      disabled={disabled}
      className={buttonStyles}
    >
      {renderContent()}
    </button>
  )
}

export default Button
