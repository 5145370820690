import { captureException } from '@sentry/react'
import { useEffect } from 'react'

const SilentFullPageErrorFallback = ({ error }: { error: any }) => {
  useEffect(() => {
    captureException(error)
    setTimeout(() => location.reload(), 1500)
  }, [error])

  return (
    <div className="fixed inset-0 bg-white flex items-center justify-center" />
  )
}

export default SilentFullPageErrorFallback
