import { ReactNode, createContext, useContext, useMemo, useState } from 'react'
import { useLoadCompanyProfile } from '@/lib/queries/CompanyProfile.queries'

interface TenantContextType {
  tenantName?: string | null
  companyName?: string | null
  customerId?: string | null
  setCustomerIdHandler: (id: string) => void
  removeCustomerIdHandler: () => void
}

const TenantContext = createContext<TenantContextType | undefined>(undefined)

interface TenantProviderProps {
  children: ReactNode
}

export const TenantProvider: React.FC<TenantProviderProps> = ({ children }) => {
  const initialCustomerId = localStorage.getItem('customerId')
  const [customerId, setCustomerId] = useState<string | undefined>(
    initialCustomerId || undefined
  )

  const { data: company } = useLoadCompanyProfile(
    false,
    undefined,
    !!customerId
  )
  const { data: tenant } = useLoadCompanyProfile(true, customerId, !!customerId)

  const setCustomerIdHandler = (id: string) => {
    localStorage.setItem('customerId', id)
    setCustomerId(id)
  }

  const removeCustomerIdHandler = () => {
    localStorage.removeItem('customerId')
    setCustomerId(undefined)
  }

  const TenantContextValue = useMemo(
    () => ({
      tenantName: tenant?.name,
      companyName: company?.name,
      customerId,
      setCustomerIdHandler,
      removeCustomerIdHandler,
    }),
    [tenant, company?.name, customerId]
  )

  return (
    <TenantContext.Provider value={TenantContextValue}>
      {children}
    </TenantContext.Provider>
  )
}

export const useTenant = (): TenantContextType => {
  const context = useContext(TenantContext)

  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider')
  }
  return context
}
