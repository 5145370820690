import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { m, LazyMotion, domAnimation } from 'framer-motion'
import { lazy } from 'react'

import { useTenant } from '@/lib/provider/TenantProvider'

const Navigation = lazy(() => import('@/layouts/partials/Navigation'))
const CustomerBanner = lazy(() => import('@/components/CustomerBanner'))
const TenantNavigation = lazy(
  () => import('@/layouts/partials/TenantNavigation')
)
const Footer = lazy(() => import('@/layouts/partials/Footer'))

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.99,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 0.9,
  },
}

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5,
}

const MemberLayout = () => {
  const location = useLocation()
  const { customerId } = useTenant()

  // Add this function to check if the current route is a sub-route of tickets
  const shouldNotAnimate = () => {
    return (
      location.pathname.startsWith('/tickets/') &&
      location.pathname !== '/tickets'
    )
  }

  return (
    <LazyMotion features={domAnimation} strict>
      <div
        className="flex flex-col min-h-full w-full bg-brand-1"
        id="member-layout"
        data-cy="member-layout"
      >
        {customerId ? (
          <>
            <CustomerBanner />
            <TenantNavigation />
          </>
        ) : (
          <Navigation />
        )}

        <div className="flex-1 flex bg-neutral-10 rounded-xl mx-2 mb-2 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.6)]">
          <main className="min-h-full overflow-y-auto flex-1">
            {shouldNotAnimate() ? (
              <Outlet />
            ) : (
              <m.div
                key={location.pathname}
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <Outlet />
              </m.div>
            )}
          </main>
        </div>

        <Footer className="px-6" />
      </div>
    </LazyMotion>
  )
}

export default MemberLayout
