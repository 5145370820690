import Button from '@/components/Button'

import ChipIcon from '@icons/cpu-chip'

const ErrorHappened = () => {
  return (
    <div
      id="error-happened"
      data-cy="error-happened"
      className="min-w-screen min-h-screen bg-brand-4 flex items-center p-5 lg:p-20 overflow-hidden relative"
    >
      <div className="flex-1 flex flex-col-reverse md:flex-row min-h-full mx-auto rounded-3xl bg-white shadow-xl p-10 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left max-w-3xl">
        <div className="w-full md:w-2/3 z-10">
          <div className="mb-6 md:mb-20 mt-12 md:mt-0 text-gray-600 font-light">
            <h1 className="font-black uppercase text-3xl lg:text-5xl text-brand-4 mb-4">
              Oops!
            </h1>
            <p className="text-lg">Seems like something wrong happened.</p>
          </div>

          <div className="flex justify-center md:justify-start">
            <Button
              style="primary"
              onClick={() => location.reload()}
              className="text-lg outline-none focus:outline-none cursor-pointer"
            >
              Refresh Page
            </Button>
          </div>
        </div>

        <div className="w-full flex md:w-1/3 text-brand-4 z-10 justify-center md:justify-end">
          <ChipIcon className="md:w-64 w-32 md:h-64 h-32" />
        </div>
      </div>

      <div className="w-64 md:w-96 h-96 md:h-full bg-blue-200 bg-opacity-30 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform" />
      <div className="w-96 h-full bg-indigo-200 bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45 transform" />
    </div>
  )
}

export default ErrorHappened
