import { createContext, useContext, useReducer } from 'react'

interface AppProviderState {
  rightSidebar: boolean
  waffleFlags: string[]
  isAddServiceShown: boolean
}

export const reducer = (state: AppProviderState, action: any) => {
  switch (action.type) {
    case 'showRightSidebar':
      return {
        ...state,
        rightSidebar: true,
      }

    case 'closeRightSidebar':
      return {
        ...state,
        rightSidebar: false,
      }

    case 'waffleFlags':
      return {
        ...state,
        waffleFlags: action.payload,
      }

    case 'showAddService':
      return {
        ...state,
        isAddServiceShown: action.payload,
      }

    default:
      return state
  }
}

export const initialState = {
  rightSidebar: false,
  waffleFlags: [],
  isAddServiceShown: false,
  isGoogleMapsLoaded: false,
}

export const AppContext = createContext({
  state: initialState,
  dispatch: () => null,
})
AppContext.displayName = 'AppContext'

function AppProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <AppContext.Provider value={[state, dispatch]} {...props} />
}

const useApp = (): any => {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error(`useApp must be used within a AppProvider`)
  }

  return context
}

export { AppProvider, useApp }
