/* eslint-disable @typescript-eslint/return-await */
import { useQuery } from 'react-query'

import { Country, Invoice, InvoiceStatus } from '@/lib/definitions/api.types'
import { GenericObject } from '@/lib/definitions/generic.types'
import { Notify } from '@/lib/misc/Notify'
import { ApiService } from '@/lib/services/ApiService'
import { UserService } from '@/lib/services/UserService'
import { StatusStyle } from '@/lib/definitions/types'

type InvoiceStatusLegendType = {
  [key in InvoiceStatus]: {
    label: string
    style: StatusStyle
  }
}

const invoiceStatusLegend: InvoiceStatusLegendType = {
  draft: { label: 'Draft', style: 'success' },
  open: { label: 'Open', style: 'warning' },
  overdue: { label: 'Overdue', style: 'warning' },
  cancelled: { label: 'Cancelled', style: 'warning' },
  partially_paid: { label: 'Partially Payed', style: 'warning' },
  paid: { label: 'Paid', style: 'success' },
}

const userService = new UserService()
const apiService = new ApiService()

export type CompanyUser = {
  id: number
  name: {
    firstName: string
    lastName: string
  }
  email: string
  status: {
    label: string
    style: string
  }
}

interface CitySearchRequest {
  city: string
  country?: string
}

export const useLoadUsers = (params?: GenericObject) =>
  useQuery(['fetchUsers'], async () => await userService.getUsers(), {
    onError: () => Notify.error('Could not load the users'),
    ...params,
  })

export const useLoadUser = (userId: string | number) =>
  useQuery(
    ['fetchUser', userId],
    async () => await userService.getUser(userId),
    {
      onError: () => Notify.error('Could not load this user'),
    }
  )

export const useLoadCompanyProfile = (
  tenant?: boolean,
  customerId?: string,
  enabled?: boolean
) =>
  useQuery(
    ['fetchCompanyProfile', tenant, customerId],
    async () => await userService.getCompanyProfile(tenant),
    {
      ...(enabled !== undefined && { enabled: enabled }),
      onError: () => Notify.error('Could not load your company profile'),
      select: (data) => {
        if (data.data.company) {
          return data.data.company
        }
        Notify.error('Could not load your company profile')
      },
    }
  )

export const useLoadPeeringDBInfo = () =>
  useQuery(
    ['fetchPeeringDBInfo'],
    async () => await userService.getPeeringDBInfo(),
    {
      select: (data) => data.data,
    }
  )

export const useLoadCustomerPeeringDBInfo = () =>
  useQuery(
    ['fetchCustomerPeeringDBInfo'],
    async () => await userService.getCustomerPeeringDBInfo(),
    {
      retry: false,
      select: (data) => data.data,
    }
  )

export const useLoadInvoices = () =>
  useQuery(['fetchInvoices'], async () => await apiService.getInvoices(), {
    onError: () => Notify.error('Could not load the invoices'),
    select: (data) =>
      data.data.map((invoice: Invoice) => ({
        order_number: invoice.order_number,
        amount: invoice.amount,
        date: invoice.date,
        due_date: invoice.due_date,
        status: invoiceStatusLegend[invoice.status],
        url: `${import.meta.env.VITE_PORTAL_API_HOST || ''}/invoice/download/${
          invoice.id
        }?token=${invoice.token}`,
      })),
  })

export const useLoadCountries = () =>
  useQuery(['fetchCountries'], async () => await apiService.getCountries(), {
    onError: () => Notify.error('Could not load the countries list'),
    select: (data) =>
      data.data.map((country: Country) => ({
        ...country,
      })),
  })

export const useLoadCities = ({ city, country }: CitySearchRequest) =>
  useQuery(
    ['fetchCities', city],
    async () => await apiService.getCities(city, country),
    {
      enabled: !!country,
      onError: () => Notify.error('Could not load the cities list'),
      select: (data) => data.data,
    }
  )

export const useLoadApiKeys = (params?: GenericObject) =>
  useQuery(['fetchApiKeys'], async () => await userService.getApiKeys(), {
    onError: () => Notify.error('Could not load your API keys'),
    select: (data) => data.data,
    ...params,
  })

export const useLoadTwoFactorAuthStatus = () =>
  useQuery(
    ['fetchTwoFactorAuthStatus'],
    async () => await userService.getTwoFactorAuthStatus(),
    {
      retry: 0,
      onError: () => Notify.error('Could not load your 2FA status'),
      select: (data) => data.data,
    }
  )
