import axios, { AxiosPromise } from 'axios'

import {
  ApiKey,
  AutonomousSystem,
  Company,
  CompanyCreate,
  CompanyDetails,
  CompanyPatch,
  CreateApiKeyResponse,
  PeeringDBResponse,
  TwoFactorBackupResponse,
  TwoFactorSetupResponse,
  TwoFactorStatusResponse,
  User,
  UserCreate,
} from '@/lib/definitions/api.types'
import { apiUrl, getAxiosRequestConfigWithHeaders } from '@/lib/misc/Utils'

export interface UpdateUserPasswordRequest {
  old_password: string
  new_password1: string
  new_password2: string
}

export interface UpdateUserResponse {
  user: {
    pk: string
    username: string
    email: string
    first_name: string
    last_name: string
  }
}

export interface GenericApiSuccessResponse {
  result: 'ok'
}

export interface ResetPasswordRequest {
  uid: string
  token: string
  new_password1: string
  new_password2: string
}

export interface AddUserApiKeyRequest {
  label: string
  expires_at: string | Date
}

export class UserService {
  updateUser(userId: number | string, data: UserCreate): AxiosPromise {
    return axios.put(
      apiUrl(`/api/v1/user/${userId}`),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  updateUserPassword(data: UpdateUserPasswordRequest): AxiosPromise {
    return axios.post(
      apiUrl('/auth/password/change/'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  createCompanyProfile(data: CompanyCreate): AxiosPromise {
    return axios.post(
      apiUrl('/api/v1/company/'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  getCompanyProfile(tenant?: boolean): AxiosPromise<CompanyDetails> {
    return axios.get(
      apiUrl('/api/v1/company/'),
      getAxiosRequestConfigWithHeaders(undefined, undefined, tenant)
    )
  }

  updateCompanyProfile(
    data: Company,
    companyId: number | string
  ): AxiosPromise {
    return axios.put(
      apiUrl(`/api/v1/company/${companyId}`),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  updateInvoiceEmails(
    data: CompanyPatch,
    companyId: number | string
  ): AxiosPromise {
    return axios.patch(
      apiUrl(`/api/v1/company/${companyId}`),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  getPeeringDBInfo(): AxiosPromise<PeeringDBResponse> {
    return axios.get(
      apiUrl('/api/v1/as-info/'),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getCustomerPeeringDBInfo(): AxiosPromise<PeeringDBResponse> {
    return axios.get(
      apiUrl('/api/v1/as-info/peeringdb'),
      getAxiosRequestConfigWithHeaders()
    )
  }

  updatePeeringDBInfo(data: AutonomousSystem): AxiosPromise<AutonomousSystem> {
    return axios.put(
      apiUrl(`/api/v1/as-info/`),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  getUsers(): AxiosPromise<User[]> {
    return axios.get(
      apiUrl('/api/v1/user/'),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getUser(userId: string | number): AxiosPromise<User> {
    return axios.get(
      apiUrl(`/api/v1/user/${userId}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  deleteUser(userId: number | string): AxiosPromise<GenericApiSuccessResponse> {
    return axios.delete(
      apiUrl(`/api/v1/user/${userId}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  addUser(user: UserCreate): AxiosPromise<User> {
    return axios.post(
      apiUrl('/api/v1/user/'),
      user,
      getAxiosRequestConfigWithHeaders()
    )
  }

  verifyUser(confirmationToken: string): AxiosPromise {
    return axios.post(
      apiUrl(`/auth/registration/account-confirm-email/${confirmationToken}/`),
      {
        key: confirmationToken,
      }
    )
  }

  requestResetPassword(email: string): AxiosPromise {
    return axios.post(apiUrl('/auth/password/reset/'), {
      email,
    })
  }

  resetPassword(data: ResetPasswordRequest): AxiosPromise {
    return axios.post(
      apiUrl(`/auth/password/reset/confirm/${data.uid}/${data.token}`),
      data
    )
  }

  resendUserVerificationEmail(
    userId: number | string
  ): AxiosPromise<GenericApiSuccessResponse> {
    return axios.post(
      apiUrl(`/api/v1/user/resend-invitation/${userId}`),
      { user_id: userId },
      getAxiosRequestConfigWithHeaders()
    )
  }

  getApiKeys(): AxiosPromise<ApiKey[]> {
    return axios.get(
      apiUrl('/api/v1/api-key/'),
      getAxiosRequestConfigWithHeaders()
    )
  }

  addApiKey(data: AddUserApiKeyRequest): AxiosPromise<CreateApiKeyResponse> {
    return axios.post(
      apiUrl('/api/v1/api-key/'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  deleteApiKey(prefix: string): AxiosPromise<ApiKey> {
    return axios.delete(
      apiUrl(`/api/v1/api-key/${prefix}`),
      getAxiosRequestConfigWithHeaders()
    )
  }

  getTwoFactorAuthStatus(): AxiosPromise<TwoFactorStatusResponse> {
    return axios.get(
      apiUrl('/api/v1/two-factor/status/'),
      getAxiosRequestConfigWithHeaders()
    )
  }

  setupTwoFactorAuth(): AxiosPromise<TwoFactorSetupResponse> {
    return axios.post(
      apiUrl('/api/v1/two-factor/setup/'),
      {},
      getAxiosRequestConfigWithHeaders()
    )
  }

  setupTwoFactorAuthConfirm(
    token: string
  ): AxiosPromise<TwoFactorBackupResponse> {
    return axios.post(
      apiUrl('/api/v1/two-factor/setup/confirm/'),
      { token },
      getAxiosRequestConfigWithHeaders()
    )
  }

  exposeTwoFactorBackup(token: string): AxiosPromise<TwoFactorBackupResponse> {
    return axios.post(
      apiUrl('/api/v1/two-factor/backup/expose/'),
      { token },
      getAxiosRequestConfigWithHeaders()
    )
  }

  disableTwoFactorAuth(): AxiosPromise<TwoFactorStatusResponse> {
    return axios.delete(
      apiUrl('/api/v1/two-factor/'),
      getAxiosRequestConfigWithHeaders()
    )
  }

  regenerateTwoFactorBackup(): AxiosPromise<TwoFactorBackupResponse> {
    return axios.post(
      apiUrl('/api/v1/two-factor/backup/regenerate/'),
      {},
      getAxiosRequestConfigWithHeaders()
    )
  }
}
