import { captureException } from '@sentry/react'
import { Component, ReactNode } from 'react'

import ErrorHappened from '@/pages/ErrorHappened'

type ErrorBoundaryProps = { children: ReactNode }
type ErrorBoundaryState = { hasError: Boolean }

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any) {
    captureException(error)
    this.setState({ hasError: true })
  }

  render() {
    return this.state.hasError ? <ErrorHappened /> : this.props.children
  }
}
